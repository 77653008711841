@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"), local("Circular-Std-Black"),
    url("../public/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), local("Circular-Std-Black"),
    url("../public/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, p {
  font-family: "Gilroy-Medium";
}

a,
a:hover {
  text-decoration: none;
}

select:hover {
  cursor: pointer;
}

.search-container > .react-select-container {
  height: 48px;
  color: #444;
  border: none;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.0047em;
  border-radius: 0px;
  text-transform: uppercase;
  line-height: 20px;
  background-color: white;
  cursor: pointer !important;
  text-transform: uppercase;
}

.option-focus,
.option-normal {
  background-color: #ffffff !important;
  font-size: 12px !important;
  font-weight: bold;
  color: #9b9b9b !important;
  margin-bottom: 12px;
  line-height: 22px;
  cursor: pointer !important;
  margin: 0px;
  padding: 0 15px !important;
  margin-bottom: 12px;
  height: auto;
  text-transform: uppercase;
}

.option-focus {
  background: none !important;
  color: #ff1628 !important;
}

.react-select-container > .normal-state {
  cursor: pointer;
  height: 48px;
  background: #ffffff;
  border: 1px solid #11131c;
  border-radius: 0px;
}

.react-select-container > .is-open {
  height: 48px;
  background: #ffffff;
  border: 1px solid #11131c;
  border-radius: 0px;
}

.tooltip_baloom {
  border: 4px solid pink;
}

.apexcharts-tooltip {
  background: #ffffff;
  width: 300px;
  height: 100px;
  font-size: 18px !important;
  white-space: normal !important;
  line-height: 28px !important;
  letter-spacing: 0px;
  font-family: "Gilroy";
  font-weight: 500 !important;
  position: absolute important;
  /* display: inline-block; */
  opacity: 0;
  text-align: center;
  z-index: 1;
  box-shadow: 0px 5px 30px 0px rgb(0 0 0 / 30%) !important;
  padding: 12px 16px !important;
  border-radius: 16px !important;
  /* transition: none; */
  color: #11131c !important;
}

.apexcharts-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -20px;
  border: 20px solid transparent;
  border-top-color: white;
}
.option-location-focus,
.option-location-normal,
.option-location-selected {
  background: none !important;
  cursor: pointer !important;
  text-decoration: none !important;
  border: none !important;
  font-size: 12px !important;
  font-family: "Gilroy-Bold";
  color: #9b9b9b !important;
  line-height: 20px;
  height: auto;
  letter-spacing: 0px;
}

.option-location-focus {
  color: #ff1628 !important;
}

.option-location-selected {
  background: none !important;
  color: #5c6066 !important;
}

.control-location-focused, .control-location-normal {
  border-color: #dadada !important;
    border-width: 1px !important;
    text-align: left;
    max-width: 340px;
    height: 48px !important;
    line-height: 47px !important;
    padding: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    line-height: 47px !important;
    padding: 0;
    padding-right: 6px !important;
    padding-left: 6px !important;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    width: 200px !important;
}

.select-dropdown-locations {
  width: 200px;
  height: 48px;
  max-height: 48px;
  font-weight: 700 !important;
  color: #444;
  cursor: pointer !important;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 12px;
  letter-spacing: 0.5px !important;
  border-radius: 4px;
  border: 1px solid #ebebeb !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.select-dropdown-locations > div:last-of-type {
  margin-top: 0px;
  border-radius: 0;
  padding: 0px;
  box-shadow: none;
  letter-spacing: 0.5px;
  border-top: none;
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-sizing: border-box;
  display: block;
  border-color: #dadada !important;
  border-width: 1px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
